<template>
	<body>
	<div class="w3-cell-row container">
		<comp-core-side-nav
			:role="role" :client="userInfo.CLIENT_ID"/>

		<comp-core-view
			:userInfo="userInfo"
			:role="role"
			:companyOrAgentType="companyOrAgentType" />
	</div>
	<div class="w3-cell-bottom footer">2020 COPYRIGHT ©ANT ALL RIGHT RESEVED.</div>

	</body>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'CompCoreIndex',

	components: {
		CompCoreSideNav: () => import('./components/core/SideNav'),
		CompCoreView: () => import('./components/core/View'),
	},
	computed: {
		...mapState([
			'auth', 'user'
		]),
	},
	data: () => ({
		expandOnHover: false,
		//권한 관련
		isUser: false,
		isAgent: false,
		isAgentManager: false,
		isClientManager: false,
		isManager: false,
		role: '',
		companyOrAgentType: '',

		//사용자 정보 관련
		userInfo: {}, //  uesr & agent 정보

	}),
	methods: {
		//created 시점에 활성화
		getUserRole() {
			this.$store.dispatch('auth/authenticate')
				.then(
					() => {

						if (this.auth.userRole.ROLE.includes('USER')) {
							this.getUserInfo();
							// this.$router.push({name: 'auth_hometax_user'})
						} else if (this.auth.userRole.ROLE.includes('AGENT')) {
							this.getAgentInfo();
						}
					}
				).catch(
				error => {
					console.log(error.response)
				}
			)
		},
		getAgentInfo() {
			this.$store.dispatch('user/getAgentInfo')
				.then(
					() => {
						if (this.user.userInfo != null ) {
							//  현 사용자의 선택 계정 관련 구분값
              //  세무대리인 비세무대리인 화면 구분값
							this.companyOrAgentType = this.user.userInfo.COMPANY_OR_AGENT_FOR_VIEW;

							this.$router.push({name: 'auth_hometax_agent'});
						}
					}
				)
		},
		getUserInfo() {
			this.$store.dispatch('user/getUserInfo')
				.then(
					() => {
						if (this.user.userInfo != null ) {
							//  현 사용자의 선택 계정 관련 구분값
							this.companyOrAgentType = this.user.userInfo.COMPANY_OR_AGENT_FOR_VIEW;

							this.$router.push({name: 'publiccorporation'});
						}
					}
				)
		},
		getRole() {
			//manager : 여긴 추후 보강
			if (this.auth.userRole.ROLE.includes('MANAGER')) {
				return 'MANAGER';
				//agent_manager
			} else if (this.auth.userRole.ROLE.includes('AGENT_MANAGER')) {
				return 'AGENT_MANAGER';
				//client_manager
			} else if (this.auth.userRole.ROLE.includes('CLIENT_MANAGER')) {
				return 'CLIENT_MANAGER';
			} else {
				//user
				if (this.auth.userRole.ROLE.includes('USER') && !this.auth.userRole.ROLE.includes('AGENT')) {
					return 'USER';
				} else {
					//agent
					return 'AGENT';
				}
			}
		},
		setUserInfo() {
			this.companyOrAgentType = this.$store.state.user.userInfo.COMPANY_OR_AGENT_FOR_VIEW;

			//  현 사용자의 선택 계정이 개인/법인 사업장일 때
			if (this.companyOrAgentType == 'COMPANY') {

				// USER : 사업장 변경 시 Appbar 텍스트 변경 위해서 state watch
				let newUserInfo = this.$store.state.user.userInfo;

				let obj = {};
				obj = {
          CLIENT_ID : newUserInfo.CLIENT_ID,
					CLIENT_MEMBER_ID : newUserInfo.CLIENT_MEMBER_ID,
					CLIENT_MEMBER_COMPANY_NAME : newUserInfo.CLIENT_MEMBER_COMPANY_NAME
				};
				this.userInfo = obj;

			} else {
				//  현 사용자의 선택 계정이 세무대리인일 때
				let obj = {};
				obj = {
          CLIENT_ID : this.user.userInfo.CLIENT_ID,
					CLIENT_MEMBER_ID : this.user.userInfo.CLIENT_MEMBER_ID,
					TAX_AGENT_NAME : this.user.userInfo.TAX_AGENT_NAME
				};
				this.userInfo = obj;

			}
		}
	},
	watch : {
		'$store.state.auth.userRole'() {    //locatStorage의 userRole 변경 시
			// route name이 first_access 일 때 !

			//	role read
			this.role = this.getRole();
		},
		'$store.state.user.userInfo'() {    //locatStorage의 userInfo 변경 시
			// route name이 first_access 일 때, 아닐 때(사업장 변경시) 모두 해당!
			//  @@ 근데 여기 찜찜
			this.setUserInfo();
		},
	},
	created() {
		if(this.$route.name == 'first_access'){
			this.getUserRole();
		}
	},
	mounted() {
		if (window.performance.navigation.type == 1) {
			// parent > child 넘기는 걸로 바꾸니까. 새로고침하면 안보임 > 새로고침시 index.vue에서 권한 및 유저정보 새로 읽어들이도록

			//role read
			this.role = this.getRole();

			//userInfo read
			this.setUserInfo();
		}

	},
}
</script>